import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

declare var jQuery:any;

@Component({
  selector: 'app-qrcodesmart',
  templateUrl: './qrcodesmart.component.html',
  styleUrls: ['./qrcodesmart.component.scss']
})
export class QrcodesmartComponent implements OnInit {
  pdfs: any = [{
    title:"Notice d’utilisation Eclairage connecté (wifi/Bluetooth)",
    path:"https://api.arlux-lighting.fr/notices/Arlux_SMART_Notice_Connexion_Parametrage_Luminaire_Wifi_v1.0.2.pdf"
  },
  {
    title:"Notice d’utilisation projecteur-caméra connecté (wifi)",
    path:"https://api.arlux-lighting.fr/notices/Arlux_SMART_Notice_Home_Secure_Connection_v1.0.3.pdf"
  }];

  constructor( private route: ActivatedRoute,) {
  }

  ngOnInit() {
    
  }

  ngOnDestroy() {
    
  }

}
