import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private prefix = 'products/';
  private prefixAdmin = 'admin/products/';

  constructor(private http: HttpClient) {
  }

  all(query, page, limit = 20) {
    return this.http.get(this.prefix + 'search/?q=' + query + '&page=' + page + '&limit=' + limit);
  }

  sync() {
    return this.http.get(this.prefixAdmin + 'import-fromDB/?type=arlux-lighting');
  }

  allProducts() {
    return this.http.get(this.prefix + 'search');
  }

  one(slug) {
    return this.http.get(this.prefix + slug);
  }

  delete(id) {
    return this.http.delete(this.prefixAdmin + id);
  }

  update(id, item) {
    return this.http.put(this.prefixAdmin + id, item);
  }

  store(item) {
    return this.http.post(this.prefixAdmin, item);
  }

  import(products) {
    return this.http.post(this.prefixAdmin + 'import', {products});
  }

}
