import { BrowserModule } from "@angular/platform-browser";
import { Injectable, NgModule } from "@angular/core";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { BackHomeComponent } from "./components/back/back-home/back-home.component";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { JwtModule } from "@auth0/angular-jwt";

import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient } from "@angular/common/http";
import { NgxHmCarouselModule } from "ngx-hm-carousel";

/*export function createTranslateLoader(http: HttpClient) {
console.dir(http)
  return new TranslateHttpLoader(http, '../../../assets/i18n/', '.json');
}*/

import {
  MatButtonModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatTabsModule,
  MatListModule,
  MatGridListModule,
  MatExpansionModule,
  MatSidenavModule,
  MatToolbarModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatCardModule,
  MatDialogModule,
  MatSnackBarModule,
  MatSelectModule,
  MatChipsModule,
  MatDatepickerModule,
  MatNativeDateModule,
} from "@angular/material";
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";

import { Observable } from "rxjs/index";

import { environment } from "../environments/environment";
import { BackIndexComponent } from "./components/back/back-index/back-index.component";
import { LayoutModule } from "@angular/cdk/layout";
import { ArluxDatatableComponent } from "./components/includes/arlux-datatable/arlux-datatable.component";
import {
  BackProductsComponent,
  ProductAddComponent,
  ProductEditComponent,
} from "./components/back/back-products/back-products.component";
import { DialogConfirmComponent } from "./components/includes/dialog-confirm/dialog-confirm.component";
import { HomeComponent } from "./components/front/home/home.component";
import { CategoryComponent } from "./components/front/category/category.component";
import { AboutComponent } from "./components/front/about/about.component";
import { ContactComponent } from "./components/front/contact/contact.component";
import { EventComponent } from "./components/front/event/event.component";
import { VideosComponent } from "./components/front/videos/videos.component";
import { VideoComponent } from "./components/front/video/video.component";
import { QrcodesmartComponent } from "./components/front/qrcodesmart/qrcodesmart.component";
import { CatalogComponent } from "./components/front/catalog/catalog.component";
import { DocumentComponent } from "./components/front/document/document.component";
import { CategoriesComponent } from "./components/front/categories/categories.component";
import { ProductComponent } from "./components/front/product/product.component";
import { SearchComponent } from "./components/front/search/search.component";
import { GalleryBoxComponent } from "./components/includes/gallery-box/gallery-box.component";
import { IndexComponent } from "./components/front/index/index.component";
import { FileUploadModule } from "ng2-file-upload";
import {
  BackCategoriesComponent,
  CategoryAddComponent,
  CategoryEditComponent,
} from "./components/back/back-categories/back-categories.component";
import {
  BackEventsComponent,
  EventAddComponent,
  EventEditComponent,
} from "./components/back/back-events/back-events.component";

import {
  BackVideosComponent,
  VideoAddComponent,
  VideoEditComponent,
} from "./components/back/back-videos/back-videos.component";

import { GalleriesBoxComponent } from "./components/includes/galleries-box/galleries-box.component";
import {
  BackNewsComponent,
  NewsAddComponent,
  NewsEditComponent,
} from "./components/back/back-news/back-news.component";
import { LoginComponent } from "./components/front/login/login.component";
import { AuthGuard } from "./guard/auth.guard";
import { AuthService } from "./services/auth.service";
import {
  BackDossiersComponent,
  DossiersAddComponent,
  DossiersEditComponent,
} from "./components/back/back-dossiers/back-dossiers.component";
import { MentionsComponent } from "./components/front/mentions/mentions.component";
import { InfoComponent } from "./components/front/product/info.component";

/*let base_url = 'http://localhost:3333';
let base_url_route = 'http://localhost:3333/api/auth';*/

// if (environment.production) {
let base_url = "https://api.arlux-lighting.fr";
let base_url_route = "https://api.arlux-lighting.fr/api/auth";

// }

@Injectable()
export class ExampleInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // let url = 'http://localhost:3333/api/v1/';
    // if (environment.production) {
    let url = "https://api.arlux-lighting.fr/api/v1/";
    // }
    if (!req.url.includes("https://api.pim-arlux.fr")) {
      req = req.clone({
        url: url + req.url,
      });
    }

    return next.handle(req);
  }
}

export function tokenGetter() {
  return localStorage.getItem("arlux_access_token");
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CategoryComponent,
    AboutComponent,
    ContactComponent,
    EventComponent,
    VideosComponent,
    VideoComponent,
    QrcodesmartComponent,
    CatalogComponent,
    MentionsComponent,
    DocumentComponent,
    CategoriesComponent,
    ProductComponent,
    InfoComponent,
    IndexComponent,
    BackHomeComponent,
    SearchComponent,
    BackIndexComponent,
    ArluxDatatableComponent,
    BackProductsComponent,
    DialogConfirmComponent,
    GalleryBoxComponent,
    GalleriesBoxComponent,
    ProductEditComponent,
    ProductAddComponent,
    BackCategoriesComponent,
    CategoryEditComponent,
    CategoryAddComponent,
    BackEventsComponent,
    EventEditComponent,
    EventAddComponent,

    BackVideosComponent,
    VideoEditComponent,
    VideoAddComponent,

    BackNewsComponent,
    NewsEditComponent,
    NewsAddComponent,
    DossiersEditComponent,
    DossiersAddComponent,
    LoginComponent,
    BackDossiersComponent,
  ],
  imports: [
    NgxHmCarouselModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatTabsModule,
    MatListModule,
    MatGridListModule,
    MatExpansionModule,
    MatSidenavModule,
    LayoutModule,
    MatToolbarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCardModule,
    MatDialogModule,
    MatSnackBarModule,
    MatSelectModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FileUploadModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
      },
    }),
    TranslateModule.forRoot(/*{
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }*/),
  ],
  exports: [],
  entryComponents: [
    DialogConfirmComponent,

    ProductEditComponent,
    ProductAddComponent,

    CategoryEditComponent,
    CategoryAddComponent,

    EventEditComponent,
    EventAddComponent,

    VideoEditComponent,
    VideoAddComponent,

    NewsEditComponent,
    NewsAddComponent,

    DossiersEditComponent,
    DossiersAddComponent,

    GalleryBoxComponent,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ExampleInterceptor, multi: true },
    AuthGuard,
    AuthService,
    MatDatepickerModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
