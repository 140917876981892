import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CategoryService} from '../../../services/category.service';
import {getColorByCategory, getInfosCategoryByTitle, getTreeCategories} from '../../../Helpers';

declare var jQuery;

@Component({
    selector: 'app-category',
    templateUrl: './category.component.html',
    styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
    gamme = null;
    category = null;
    serie = null;

    currentGammeSlug = '';

    countProducts = {value: 0};
    search = '';

    gammeSlug = '';
    categorySlug = '';
    serieSlug = '';

    categoryInfos = {image: '', subtitle: ''};
    subcategories = [];
    gammeCategories = [];
    series = [];
    title = '';
    products = [];
    allProducts = [];
    sub: any;

    loading = false;

    getBackgroundColorCategory(title) {
        return {'background-color': getColorByCategory(title)};
    }

    constructor(private categoryService: CategoryService, private route: ActivatedRoute) {
    }

    handlerSearch(event: any) {
        const value = event.target.value;
        const _products = this.allProducts;
        const re = new RegExp(value, 'gi');
        if (value !== '') {
            this.products = _products.filter(p => {
                if (p.name.match(re)) {
                    return p;
                }
            });
            this.countProducts = {value: this.products.length};
        } else {
            this.products = this.allProducts;
            this.countProducts = {value: this.products.length};
        }

    }

    ngOnInit() {

        /*this.sub = this.route.params.subscribe(params => {
            this.series = [];
            let slug = params['slug'];
            this.categoryService.one(slug).subscribe(category => {
                this.category = category;
                document.title =  category.title + " - CATÉGORIE";
                this.subcategories = category.subcategories;
                let title = this.category.title;
                if (category.parent) {
                    this.parent = category.parent;
                    title = this.parent.title;
                    this.categoryService.one(this.parent.slug).subscribe(parent => {
                        this.subcategories = parent.subcategories;
                    });
                    this.categoryService.products(category.slug).subscribe(products => this.products = products);
                } else {
                    if (category.subcategories.length > 0) {
                        this.categoryService.one(category.subcategories[0].slug).subscribe(cat => {
                            this.category = cat;
                            this.subcategories[0].series = cat.subcategories;
                        });
                        this.categoryService.products(category.subcategories[0].slug).subscribe(products => this.products = products);
                    }
                }
                this.title = title;
                this.categoryInfos = getInfosCategoryByTitle(title);
            });
        });*/


        this.sub = this.route.params.subscribe(async params => {

            this.loading = true;

            this.gammeSlug = params['gamme'];
            this.categorySlug = params['categorie'];
            this.serieSlug = params['serie'];

            this.allProducts = [];

            this.serie = null;
            this.products = [];
            this.countProducts = {value: 0};
            this.series = [];

            let pageTitle = '';

            this.subcategories = [];
            if (this.currentGammeSlug !== this.gammeSlug) {
                this.gammeCategories = getTreeCategories(this.gammeSlug);
            }
            console.log(this.gammeCategories)
            this.currentGammeSlug = this.gammeSlug;

            const allCategories = [];

            const gamme = await this.categoryService.one(this.gammeSlug).toPromise();
            const title = gamme.title;
            this.gamme = gamme;
            pageTitle = 'GAMME ' + gamme.title + ' - ARLUX-LIGHTING';
            this.gammeCategories.forEach(async (cat, index) => {
                const cats = [];
                const category = await this.categoryService.one(cat.slug).toPromise();
                if (category) {
                    if (this.gammeCategories[index].series.length === 0) {
                        this.gammeCategories[index].series = category.subcategories;
                    }
                    if (!this.categorySlug) {
                        cats.push(category._id);
                        allCategories.push(category._id);
                        console.log(1);
                        category.subcategories.forEach(serie => {
                            cats.push(serie._id);
                            allCategories.push(serie._id);
                            console.log(2);
                        });
                    }
                }

                /*this.categoryService.productsCategories(cats).subscribe(products => {
                    products.map(p => this.products.push(p));
                    this.allProducts = this.products;
                    this.countProducts = {value: this.products.length};
                    setTimeout(() => this.loading = false, 500);
                });*/

            });

            if (this.categorySlug) {
                const productsCategorie = [];
                const category = await this.categoryService.one(this.categorySlug).toPromise();
                this.category = category;
                pageTitle = 'CATÉGORIE ' + category.title + ' - ARLUX-LIGHTING';
                if (!this.serieSlug) {
                    productsCategorie.push(category._id);
                    allCategories.push(category._id);
                    console.log(3);
                    category.subcategories.forEach(serie => {
                        productsCategorie.push(serie._id);
                        allCategories.push(serie._id);
                        console.log(4);
                    });

                    /*this.categoryService.productsCategories(productsCategorie).subscribe(products => {
                        products.map(p => this.products.push(p));
                        this.allProducts = this.products;
                        this.countProducts = {value: this.products.length};
                        setTimeout(() => this.loading = false, 500);
                    });*/
                    document.title = pageTitle;
                } else {
                    const serie = await this.categoryService.one(this.serieSlug).toPromise();
                    this.serie = serie;
                    pageTitle = 'SÉRIE ' + serie.title + ' - ARLUX-LIGHTING';
                    allCategories.push(serie._id);
                    console.log(5);
                    /*this.categoryService.products(this.serieSlug).subscribe(products => {
                        products.map(p => this.products.push(p));
                        this.allProducts = this.products;
                        this.countProducts = {value: this.products.length};
                        setTimeout(() => this.loading = false, 500);
                    });*/
                    document.title = pageTitle;

                }

            } else {
                document.title = pageTitle;
            }

            setTimeout(() => {
                console.log(6);
                this.categoryService.productsCategories(allCategories).subscribe(products => {
                    if (products) {
                        products.map(p => this.products.push(p));
                        this.allProducts = this.products;
                        this.countProducts = {value: this.products.length};
                        setTimeout(() => this.loading = false, 500);
                    }
                });
            }, 2000);


            this.title = title;
            this.categoryInfos = getInfosCategoryByTitle(title);

        });
    }

    // tslint:disable-next-line:use-life-cycle-interface
    ngOnDestroy() {
        this.sub.unsubscribe();
    }
}
