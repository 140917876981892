import {Component, Inject, OnInit} from '@angular/core';
import {DialogConfirmComponent} from '../../includes/dialog-confirm/dialog-confirm.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {FileUploader, FileSelectDirective} from 'ng2-file-upload/ng2-file-upload';
import {DossiersService} from "../../../services/dossiers.service";

@Component({
  selector: 'app-back-dossiers',
  templateUrl: './back-dossiers.component.html',
  styleUrls: ['./back-dossiers.component.scss']
})
export class BackDossiersComponent implements OnInit {

  public title = 'Liste des Dossiers';
  filter = '';
  page = 1;
  limit = 5;
  model = {
    dr: '', update: '', supplier_prod_vlm: '',
    shipment_request_date: '',
    supplier_delevery_date: '',
    estimated_finish_date: '',
    remark: '',
    order_status: '',
    artwork_remark: '',
    artwork_color_box: '',
    artwork_manual: '',
    artwork_label: '',
    inspection_date: '',
    inspection_rework_status: '',
    inspection_rework_remark: '',
    inspection_rework_new_inspect_date: '',
    inspection_ok_quality_date: '',
    shipment_ens: '',
    shipment_clg: '',
    shipment_etd: '',
    shipment_eta: '',
    shipment_cont: '',
    payment_terms: '',
    payment_deposit_date: '',
    payment_deposit_amount: '',
    payment_balance_date: '',
    payment_balance_amount: ''
  };
  public datatable: any = {
    headers: ['', 'DR', 'Update', 'SUPPLIER/PROD/VLM', 'Shipment Request Date'],
    items: [],
    paginate: {}
  };

  constructor(
    private dossiersService: DossiersService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar) {

  }

  addItem() {
    let item = {...this.model};
    this.dialog.open(DossiersAddComponent, {
      width: '70vw',
      data: {item}
    }).afterClosed().subscribe(result => {
      if (result) this.search()
    });
  }

  editItem(index) {
    let item = {... this.datatable.items[index]};
    this.dialog.open(DossiersEditComponent, {
      width: '70vw',
      data: {item}
    }).afterClosed().subscribe(result => {
      if (result) this.search();

    });
  }

  deleteItem(id) {
    this.dialog.open(DialogConfirmComponent, {
      data: {title: 'Supprimer', content: 'Êtes-vous sûr?'}
    })
      .afterClosed().subscribe(result => {
      if (result) {
        this.dossiersService.delete(id).subscribe((response: any) => {
          this.search();
          if (response.status) {
            this.snackBar.open(response.message, '', {
              duration: 3000,
              panelClass: 'msg-success'
            });
            return;
          }
          this.snackBar.open(response.message, '', {
            duration: 3000,
            panelClass: 'msg-error'
          });
        })
      }
    });
  }

  search() {
    this.dossiersService.search(this.filter, this.page, this.limit).subscribe(response => {
      this.datatable.items = response['data'];
      let {lastPage, page, perPage, total}: any = response;
      this.datatable.paginate = {total, lastPage, perPage}
    });
  }

  changeFilter(val) {
    this.filter = val;
    this.search();
  }

  changePage({pageIndex = 0, pageSize = 5}) {
    this.page = pageIndex + 1;
    this.limit = pageSize;
    this.search();
  }

  ngOnInit() {
    this.dossiersService.search(this.filter, this.page, this.limit).subscribe((response: any) => {
      const {lastPage, page, perPage, total, data}: any = response;
      this.datatable.items = data;
      this.datatable.paginate = {total, lastPage, perPage};
    });
  }
}

const URL = 'http://localhost:3333/api/v1/admin/dossiers/';

@Component({
  templateUrl: './edit.component.html',
})
export class DossiersEditComponent {
  objectKeys = Object.keys;

  editItem() {
    this.dossiersService.update(this.data.item._id, this.data.item).subscribe(({status, message}: any) => {
      if (status == false) {
        this.snackBar.open(message[0].message, '', {
          duration: 3000,
          panelClass: 'msg-error'
        });
      } else {
        this.snackBar.open(message, '', {
          duration: 3000,
          panelClass: 'msg-success'
        });
        this.dialogRef.close(true);
      }

    });
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public snackBar: MatSnackBar,
              private dialogRef: MatDialogRef<DossiersEditComponent>,
              private dossiersService: DossiersService) {
  }
}

@Component({
  templateUrl: './add.component.html',
})
export class DossiersAddComponent {
  objectKeys = Object.keys;

  addItem() {
    this.dossiersService.store(this.data.item).subscribe(({status, message}: any) => {
      if (status == false) {
        this.snackBar.open(message[0].message, '', {
          duration: 3000,
          panelClass: 'msg-error'
        });
      } else {
        this.snackBar.open(message, '', {
          duration: 3000,
          panelClass: 'msg-success'
        });
        this.dialogRef.close(true);
      }
    });
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, public snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<DossiersAddComponent>,
    private dossiersService: DossiersService) {
  }
}
