import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Router} from "@angular/router";

@Injectable()
export class AuthService {
  constructor(private http: HttpClient, private router: Router) {
  }

  login(username: string, password: string): Observable<boolean> {
    return this.http.post<{ token: string }>('auth', {username, password})
      .pipe(
        map((result: any) => {
          localStorage.setItem('arlux_access_token', result.data.token);
          return true;
        })
      );
  }

  logout() {
    localStorage.removeItem('arlux_access_token');
    this.router.navigate(['login'])
  }

  public get loggedIn(): boolean {
    return (localStorage.getItem('arlux_access_token') !== null);
  }

}
