﻿import { FileUploader } from 'ng2-file-upload';
import {CategoryService} from './../../../services/category.service';
import {Component, Inject, OnInit} from '@angular/core';
import {ProductService} from '../../../services/product.service';
import {DialogConfirmComponent} from '../../includes/dialog-confirm/dialog-confirm.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {FileSelectDirective} from 'ng2-file-upload/ng2-file-upload';
import {EventAddComponent, EventEditComponent} from '../back-events/back-events.component';
import {CategoryAddComponent} from '../back-categories/back-categories.component';
import {FileUploaderOptions} from 'ng2-file-upload';


@Component({
    selector: 'app-back-products',
    templateUrl: './back-products.component.html',
    styleUrls: ['./back-products.component.scss']
})
export class BackProductsComponent implements OnInit {
    public title = 'liste des produits';
    filter = '';
    page = 1;
    limit = 5;
    model = {
        name: '',
        slug: '',
        product_s_desc: '',
        product_desc: '',
        image: '',
        category_id: '',
        description: {
            coloris: '',
            famille: '',
            lumens: '',
            'matière': '',
            'Nom de série': '',
            'puissance max': '',
            'nom de série': ''
        },
        'specification': {
            'classe': '',
            'dimension': '',
            'ip': '',
            'pcb': '',
            'poids net': '',
            'poids brut': '',
            'uvc / pal': '',
            'qt 20 P': ''
        },
    };
    public datatable: any = {
        headers: ['', 'Id', 'Nom', 'Nom de catégorie', 'Créé à', 'Mis à jour à'],
        items: [],
        paginate: {}
    };
    categories = [];

    constructor(
        private productService: ProductService,
        private categoryService: CategoryService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar) {

    }

    addItem() {
        let item = {...this.model};
        this.dialog.open(ProductAddComponent, {
            width: '70vw',
            data: {item, categories: this.categories}
        }).afterClosed().subscribe(result => {
            if (result) {
                this.search();
            }
        });
    }

    editItem(index) {
        let item = {...this.datatable.items[index]};
        this.dialog.open(ProductEditComponent, {
            width: '70vw',
            data: {item, categories: this.categories}
        }).afterClosed().subscribe(result => {
            if (result) {
                this.search();
            }
        });
    }

    deleteItem(id) {
        this.dialog.open(DialogConfirmComponent, {
            data: {title: 'Supprimer', content: 'Êtes-vous sûr?'}
        })
            .afterClosed().subscribe(result => {
            if (result) {
                this.productService.delete(id).subscribe((response: any) => {
                    this.search();
                    if (response.status) {
                        this.snackBar.open(response.message, '', {
                            duration: 3000,
                            panelClass: 'msg-success'
                        });
                        return;
                    }
                    this.snackBar.open(response.message, '', {
                        duration: 3000,
                        panelClass: 'msg-error'
                    });
                });
            }
        });
    }

    search() {
        this.productService.all(this.filter, this.page, this.limit).subscribe(response => {
            this.datatable.items = response['data'];
            let {lastPage, page, perPage, total}: any = response;
            this.datatable.paginate = {total, lastPage, perPage};
        });
    }

    changeListener(files: FileList) {
        if (files && files.length > 0) {
            let file: File = files.item(0);
            let reader: FileReader = new FileReader();
            reader.readAsText(file, 'ISO-8859-1');
            reader.onload = (e) => {
                let csv: string = reader.result;
                let products = this.extractData(csv);
                this.productService.import(products).subscribe(response => {
                    this.search();
                });
            };
        }
    }

    syncListener(): any {
        this.productService.sync().subscribe(response => {
            console.log(response);
        });
    }

    extractData(res): any[] {
        let csvData = res || '';
        let allTextLines = csvData.split(/\r\n|\n/);
        let headers = allTextLines[0].split(';');
        let lines: any[] = [];

        for (let i = 1; i < allTextLines.length; i++) {
            let data = allTextLines[i].split(';');
            if (data.length == headers.length) {
                let tarr = {};
                for (let j = 0; j < headers.length; j++) {
                    let indexH = headers[j].toLowerCase();
                    tarr[indexH] = data[j];
                }
                lines.push(tarr);
            }
        }
        return lines;
    }

    changeFilter(val) {
        this.filter = val;
        this.search();
    }

    changePage({pageIndex = 0, pageSize = 5}) {
        this.page = pageIndex + 1;
        this.limit = pageSize;
        this.search();
    }

    ngOnInit() {
        this.productService.all(this.filter, this.page, this.limit).subscribe((response: any) => {
            const {lastPage, page, perPage, total, data}: any = response;
            this.datatable.items = data;
            this.datatable.paginate = {total, lastPage, perPage};
        });
        this.categoryService.all().subscribe(categories => this.categories = categories);
    }
}

/*const URL = 'http://localhost:3333/api/v1/admin/products/';
const URL_ADMIN = 'http://localhost:3333/api/v1/admin/';*/

const URL = 'https://api.arlux-lighting.fr/api/v1/admin/products/';
const URL_ADMIN = 'https://api.arlux-lighting.fr/api/v1/admin/';

@Component({
    templateUrl: './edit.component.html',
})
export class ProductEditComponent {
    objectKeys = Object.keys;

    public uploader: FileUploader = new FileUploader({
        url: URL + this.data.item._id + '/edit-picture?token=' + localStorage.getItem('arlux_access_token'),
        itemAlias: 'photo'
    });

    ngOnInit() {

        /*this.uploader.onBeforeUploadItem = function(item){
          item.headers = {
            'Authorization': `Bearer ${localStorage.getItem('arlux_access_token')}`
          };
        };*/

        this.uploader.onAfterAddingFile = (file) => {
            file.withCredentials = false;
        };

        this.uploader.onAfterAddingFile = (file) => {
            file.withCredentials = false;
        };
        this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
            response = JSON.parse(response);
            const {url, message} = response;
            this.data.item.image = url;
            this.snackBar.open(message, '', {
                duration: 3000,
                panelClass: 'msg-success'
            });
        };
    }

    editItem() {
        this.productService.update(this.data.item._id, this.data.item).subscribe(({status, message}: any) => {
            if (status == false) {
                this.snackBar.open(message[0].message, '', {
                    duration: 3000,
                    panelClass: 'msg-error'
                });
            } else {
                this.snackBar.open(message, '', {
                    duration: 3000,
                    panelClass: 'msg-success'
                });
                this.dialogRef.close(true);
            }

        });
    }

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public snackBar: MatSnackBar,
                private dialogRef: MatDialogRef<ProductEditComponent>,
                private productService: ProductService) {
    }
}


@Component({
    templateUrl: './add.component.html',
})
export class ProductAddComponent {
    objectKeys = Object.keys;

    public uploader: FileUploader = new FileUploader({
        url: URL_ADMIN + 'add-picture/product?token=' + localStorage.getItem('arlux_access_token'),
        itemAlias: 'photo'
    });

    ngOnInit() {
        /*const authHeader: Array<{
          name: string;
          value: string;
        }> = [];
        authHeader.push({name: 'Authorization' , value: 'Bearer ' + localStorage.getItem('arlux_access_token')});
        const uploadOptions = <FileUploaderOptions>{headers : authHeader};
        this.uploader.setOptions(uploadOptions);

        this.uploader.onAfterAddingFile = (file) => {
          file.withCredentials = false;
        };*/
        this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
            response = JSON.parse(response);
            const {url, message} = response;
            this.data.item.image = url;
            this.snackBar.open(message, '', {
                duration: 3000,
                panelClass: 'msg-success'
            });
        };
    }


    addItem() {
        this.productService
            .store(this.data.item)
            .subscribe(({status, message}: any) => {
                if (status == false) {
                    this.snackBar.open(message[0].message, '', {
                        duration: 3000,
                        panelClass: 'msg-error'
                    });
                } else {
                    this.snackBar.open(message, '', {
                        duration: 3000,
                        panelClass: 'msg-success'
                    });
                    this.dialogRef.close(true);
                }
            });
    }

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public snackBar: MatSnackBar,
                private dialogRef: MatDialogRef<ProductAddComponent>,
                private productService: ProductService) {
    }
}
