import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/index';

@Injectable({
    providedIn: 'root'
})
export class CategoryService {
    private prefix = 'categories/';
    private prefixAdmin = 'admin/categories/';
    pendingRequest = null;

    constructor(private http: HttpClient) {
    }

    all(): Observable<any> {
        return this.http.get(this.prefix + 'all');
    }

    allParents(): Observable<any> {
        return this.http.get(this.prefix + 'all-parents');
    }

    allByFilter(query, page, limit = 20) {
        return this.http.get(this.prefix + 'search/?q=' + query + '&page=' + page + '&limit=' + limit);
    }

    one(slug): Observable<any> {
        return this.http.get(this.prefix + slug);
    }

    products(slug): Observable<any> {
        return this.http.get(this.prefix + slug + '/products');
    }

    productsCategories(categories, limit = 0): Observable<any> {
        return this.http.post(this.prefix + 'products-categories', {categories, limit});
    }

    delete(id) {
        return this.http.delete(this.prefixAdmin + id);
    }

    update(id, item) {
        return this.http.put(this.prefixAdmin + id, item);
    }

    store(item) {
        return this.http.post(this.prefixAdmin, item);
    }

}
