import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VideoService } from '../../../services/video.service';
import { Video } from '../../../models/Video';

declare var jQuery;

@Component({
    selector: 'app-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {
    videos: Video[];
    video: Video;

    loading = false;

    sub: any;


    constructor(private videoService: VideoService, private route: ActivatedRoute) {
    }

    ngOnInit() {


        this.sub = this.route.params.subscribe(params => {
            const videoSlug = params['video'];
            this.loading = true;

            // this.videos = [];

            this.video = null;

            this.videoService.all().subscribe(videos => {
                this.videos = videos;
            });

            this.videoService.one(videoSlug).subscribe((video) => {
                console.log(video);
                this.video = video;
            });


        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }
}
