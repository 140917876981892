import {Component, OnInit} from '@angular/core';
import {Event} from '../../../models/Event';
import {ActivatedRoute} from '@angular/router';
import {EventService} from "../../../services/event.service";

declare var jQuery:any;

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {
  event: Event;
  sub: any;

  constructor(private eventService: EventService, private route: ActivatedRoute,) {
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      let slug = params['slug'];
      this.eventService.one(slug).subscribe(event => {
        this.event = event;
        setTimeout(() => jQuery('.gallery a').simpleLightbox(), 500)
      });

    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
