import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class DossiersService {

  constructor(private http: HttpClient) {
  }

  private prefix = 'dossiers/';
  private prefixAdmin = 'admin/dossiers/';

  search(query, page, limit = 20) {
    return this.http.get(this.prefix + 'search/?q=' + query + '&page=' + page + '&limit=' + limit)
  }

  delete(id) {
    return this.http.delete(this.prefixAdmin + id)
  }

  update(id, item) {
    return this.http.put(this.prefixAdmin + id, item)
  }

  store(item) {
    return this.http.post(this.prefixAdmin, item)
  }
}
