import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DocumentService} from '../../../services/document.service';

@Component({
    selector: 'app-document',
    templateUrl: './document.component.html',
    styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit {
    documents;
    public sub: any;
    public query: any;
    public list: any = [];

    public datatable: any = {
        headers: ['', 'Titre', 'Description'],
        items: [],
        paginate: {}
    };

    constructor(private documentService: DocumentService, private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            console.log(params['slug'])
            let slug = (params['slug'] == "all-notices")? "notices": params['slug'];
            console.log(params['slug'])

            this.documentService.all(slug).subscribe((documents) => {
                this.documents = documents;
                this.list = documents.list;
            });
        });
    }

    // tslint:disable-next-line:use-life-cycle-interface
    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    download(file) {
        console.log(file);
    }

    handlerSearch(event: any) {
        this.query = event.target.value;
        if (this.query === '') {
            this.list = this.documents.list;
        } else {
            this.list = this.documents.list.filter(doc => doc.name.replace(/_/g, ' ').toLowerCase().includes(this.query));
        }
    }
}
