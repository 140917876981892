import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ProductService} from "../../../services/product.service";
import {getColorByCategory, getInfosCategoryByTitle, getTreeCategories, getBackgroundColorCategory } from '../../../Helpers';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  products;
  query = "";
  page = 1;
  lastPage = 1;
  total = 1;
  sub: any;
  title = '';

  constructor(private productService: ProductService, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      let query = params['q'];
      this.query = query;
      this.search(query)
    });
  }
  getBackgroundColorCategory(title) {
    return {'background-color': getColorByCategory(title)};
}
  search(query = "") {
    this.productService.all(query, this.page).subscribe(response => {
      this.products = response['data'];
      let {lastPage, page, perPage, total}: any = response;
      this.lastPage = lastPage;
      this.total = total;
    });
  }

  handleChangeText(e) {
    let code = e.keyCode ? e.keyCode : e.charCode;
    if (code == 13) {
      this.query = e.target.value;
      this.page = 1
      this.search(this.query);
    }
  }

  handleChange() {
    let page = this.page;
    page = isNaN(page) ? 1 : page;
    this.page = (page <= 0) ? 1 : (page > this.lastPage ? this.lastPage : page);
    this.search(this.query);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  next() {
    this.page++;
    this.search(this.query);
  }

  prev() {
    this.page--;
    this.search(this.query);
  }

}
