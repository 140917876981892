import { FileUploader } from 'ng2-file-upload';
import {Component, Inject, OnInit} from '@angular/core';
import {DialogConfirmComponent} from '../../includes/dialog-confirm/dialog-confirm.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-back-events',
  templateUrl: './back-events.component.html',
  styleUrls: ['./back-events.component.scss']
})
export class BackEventsComponent implements OnInit {
  public title = 'liste des événements';
  hoverImg = -1;
  filter = '';
  page = 1;
  limit = 5;
  model = {
    title: '', slug: '', url: '', description: '', images: []
  };
  public datatable: any = {
    headers: ['', 'Titre', 'Site Internet', 'Description'],
    items: [],
    paginate: {}
  };
  categories = [];

  constructor(
    private eventService: EventService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar) {

  }

  addItem() {
    let item = {...this.model};
    this.dialog.open(EventAddComponent, {
      width: '70vw',
      data: {item}
    }).afterClosed().subscribe(result => {
      if (result) this.search()
    });
  }

  editItem(index) {
    let item = {... this.datatable.items[index]};
    this.dialog.open(EventEditComponent, {
      width: '70vw',
      data: {item, categories: this.categories}
    }).afterClosed().subscribe(result => {
      if (result) this.search()
    });
  }

  deleteItem(id) {
    this.dialog.open(DialogConfirmComponent, {
      data: {title: 'Supprimer', content: 'Êtes-vous sûr?'}
    })
      .afterClosed().subscribe(result => {
      if (result) {
        this.eventService.delete(id).subscribe((response: any) => {
          this.search();
          if (response.status) {
            this.snackBar.open(response.message, '', {
              duration: 3000,
              panelClass: 'msg-success'
            });
            return;
          }
          this.snackBar.open(response.message, '', {
            duration: 3000,
            panelClass: 'msg-error'
          });
        })
      }
    });
  }

  search() {
    this.eventService.search(this.filter, this.page, this.limit).subscribe(response => {
      this.datatable.items = response['data'];
      let {lastPage, page, perPage, total}: any = response;
      this.datatable.paginate = {total, lastPage, perPage}
    });
  }

  changeFilter(val) {
    this.filter = val;
    this.search();
  }

  changePage({pageIndex = 0, pageSize = 5}) {
    this.page = pageIndex + 1;
    this.limit = pageSize;
    this.search();
  }

  ngOnInit() {
    this.eventService.search(this.filter, this.page, this.limit).subscribe((response: any) => {
      const {lastPage, page, perPage, total, data}: any = response;
      this.datatable.items = data;
      this.datatable.paginate = {total, lastPage, perPage};
    });
  }
}


import {FileSelectDirective} from 'ng2-file-upload/ng2-file-upload';
import {EventService} from "../../../services/event.service";
import {NewsAddComponent} from "../back-news/back-news.component";
import {NewsService} from "../../../services/news.service";

/*const URL = 'http://localhost:3333/api/v1/admin/events/';
const URL_ADMIN = 'http://localhost:3333/api/v1/admin/';*/

const URL = 'https://api.arlux-lighting.fr/api/v1/admin/events/';
const URL_ADMIN = 'https://api.arlux-lighting.fr/api/v1/admin/';

@Component({
  templateUrl: './edit.component.html',
  styles: [`
    .delete-img {
      position: absolute;
      top: 0;
      right: 0;
    }
  `]
})
export class EventEditComponent {
  objectKeys = Object.keys;
  public uploader: FileUploader = new FileUploader({
    url: URL + this.data.item._id + '/edit-picture?token=' + localStorage.getItem('arlux_access_token'),
    itemAlias: 'photo'
  });

  removeImg(i) {
    this.eventService.removeImg(this.data.item._id, i).subscribe((res: any) => {
      if (res.success) {
        let images = this.data.item.images.filter((item, index) => index != i);
        this.data.item.images = images;
        this.snackBar.open(res.message, '', {
          duration: 3000,
          panelClass: 'msg-success'
        });
      }
    })
  }

  editItem() {
    this.eventService.update(this.data.item._id, this.data.item).subscribe(({status, message}: any) => {
      if (status == false) {
        this.snackBar.open(message[0].message, '', {
          duration: 3000,
          panelClass: 'msg-error'
        });
      } else {
        this.snackBar.open(message, '', {
          duration: 3000,
          panelClass: 'msg-success'
        });
        this.dialogRef.close(true);
      }

    });
  }

  ngOnInit() {
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = JSON.parse(response);
      const {url, message} = response;
      this.data.item.images.push(url);
      this.snackBar.open(message, '', {
        duration: 3000,
        panelClass: 'msg-success'
      });
    };
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public snackBar: MatSnackBar,
              private dialogRef: MatDialogRef<EventEditComponent>,
              private eventService: EventService) {
  }
}

@Component({
  templateUrl: './add.component.html',
  styles: [`
    .delete-img {
      position: absolute;
      top: 0;
      right: 0;
    }
  `]
})
export class EventAddComponent {
  objectKeys = Object.keys;
  hoverImg = -1;
  public uploader: FileUploader = new FileUploader({
    url: URL_ADMIN + 'add-picture/event?token=' + localStorage.getItem('arlux_access_token'),
    itemAlias: 'photo'
  });

  ngOnInit() {
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = JSON.parse(response);
      const {url, message} = response;
      this.data.item.images.push(url);
      this.snackBar.open(message, '', {
        duration: 3000,
        panelClass: 'msg-success'
      });
    };
  }

  removeImg(i) {
    this.data.item.images = this.data.item.images.filter((item, index) => index != i);
    this.snackBar.open('l\'image a été supprimée', '', {
      duration: 3000,
      panelClass: 'msg-success'
    });
  }

  addItem() {
    this.eventService.store(this.data.item).subscribe(({status, message}: any) => {
      if (status == false) {
        this.snackBar.open(message[0].message, '', {
          duration: 3000,
          panelClass: 'msg-error'
        });
      } else {
        this.snackBar.open(message, '', {
          duration: 3000,
          panelClass: 'msg-success'
        });
        this.dialogRef.close(true);
      }
    });
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, public snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<EventAddComponent>,
    private eventService: EventService) {
  }
}
