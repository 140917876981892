import {Component} from '@angular/core';
import {animate, query, style, transition, trigger} from "@angular/animations";
import {Observable} from "rxjs/index";
import {BreakpointObserver, Breakpoints, BreakpointState} from "@angular/cdk/layout";
import {map} from "rxjs/internal/operators";
import {AuthService} from "../../../services/auth.service";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";

@Component({
  templateUrl: './back-index.component.html',
  styleUrls: ['./back-index.component.scss'],
  animations: [
    trigger('animRoutes', [

      transition('* => *', [

        query(':enter',
          [
            style({opacity: 0})
          ],
          {optional: true}
        ),

        query(':leave',
          [
            style({opacity: 1}),
            animate('0.01s', style({opacity: 0}))
          ],
          {optional: true}
        ),

        query(':enter',
          [
            style({opacity: 0}),
            animate('0.1s', style({opacity: 1}))
          ],
          {optional: true}
        )

      ])

    ])
  ]
})

export class BackIndexComponent {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(map(result => result.matches));

  constructor(private http: HttpClient,private breakpointObserver: BreakpointObserver, private auth: AuthService, private router:Router) {
  }

  getDepth(outlet) {
    return outlet.activatedRouteData['depth'];
  }

  logout() {
    this.http.post('logout',{});
    this.auth.logout();
  }

}
