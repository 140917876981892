import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs/index";

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  private catalogUrl = 'catalogs/';

  constructor(private http: HttpClient) {
  }

  one(): Observable<any> {
    return this.http.get(this.catalogUrl + 'one');
  }
}
