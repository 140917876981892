import { Injectable } from '@angular/core';
import { Video } from '../models/Video';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs/index";

@Injectable({
  providedIn: 'root'
})
export class VideoService {
  private prefix = 'videos/';
  private prefixAdmin = 'admin/videos/';

  constructor(private http: HttpClient) {
  }

  all(): Observable<Video[]> {
    return this.http.get<Video[]>(this.prefix + 'all');
  }

  search(query, page, limit = 20) {
    return this.http.get(this.prefix + 'search/?q=' + query + '&page=' + page + '&limit=' + limit)
  }

  one(slug): Observable<Video> {
    return this.http.get<Video>(this.prefix + 'one/' + slug);
  }

  delete(id) {
    return this.http.delete(this.prefixAdmin + id)
  }

  update(id, item) {
    return this.http.put(this.prefixAdmin + id, item)
  }

  store(item) {
    return this.http.post(this.prefixAdmin, item)
  }

  removeImg(id, index) {
    return this.http.post(this.prefixAdmin + id + '/remove-picture/' + index, {})
  }
  removeVideo(id, index) {
    return this.http.post(this.prefixAdmin + id + '/remove-video/' + index, {})
  }

}
