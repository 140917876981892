import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

    fields = {
        name: '',
        email: '',
        sujet: '',
        message: ''
    };

    constructor(private http: HttpClient, private snackBar: MatSnackBar, private translateService: TranslateService) {
    }

    ngOnInit() {
    }

    sendForm() {
        this.http.post('send-form-contact', {...this.fields}).subscribe((response) => {
            this.translateService.get('le formulaire de contact a bien été envoyé', {}).subscribe((res: string) => {
                this.snackBar.open(res, '', {
                    duration: 3000,
                    panelClass: 'msg-success'
                });
            });

        });
    }

}
