import {Component} from '@angular/core';

// @ts-ignore
@Component({
  selector: 'app-mentions',
  templateUrl: './mentions.component.html',
  styleUrls: ['./mentions.component.scss']
})
export class MentionsComponent {
  currentElem = -1;
}
