import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BackIndexComponent } from "./components/back/back-index/back-index.component";
// import routeBack from "./routes/back";
// import routeFront from "./routes/front";
import { IndexComponent } from "./components/front/index/index.component";
import { AuthGuard } from "./guard/auth.guard";
import { HomeComponent } from "./components/front/home/home.component";
import { CategoryComponent } from "./components/front/category/category.component";
import { AboutComponent } from "./components/front/about/about.component";
import { EventComponent } from "./components/front/event/event.component";
import { CatalogComponent } from "./components/front/catalog/catalog.component";
import { DocumentComponent } from "./components/front/document/document.component";
import { CategoriesComponent } from "./components/front/categories/categories.component";
import { ProductComponent } from "./components/front/product/product.component";
import { SearchComponent } from "./components/front/search/search.component";
import { ContactComponent } from "./components/front/contact/contact.component";
import { VideosComponent } from "./components/front/videos/videos.component";
import { VideoComponent } from "./components/front/video/video.component";
import { QrcodesmartComponent } from "./components/front/qrcodesmart/qrcodesmart.component";

import { LoginComponent } from "./components/front/login/login.component";
import { MentionsComponent } from "./components/front/mentions/mentions.component";
import { BackProductsComponent } from "./components/back/back-products/back-products.component";
import { BackCategoriesComponent } from "./components/back/back-categories/back-categories.component";
import { BackEventsComponent } from "./components/back/back-events/back-events.component";
import { BackNewsComponent } from "./components/back/back-news/back-news.component";
import { BackDossiersComponent } from "./components/back/back-dossiers/back-dossiers.component";
import { BackVideosComponent } from "./components/back/back-videos/back-videos.component";

const routes: Routes = [
  {
    path: 'admin', component: BackIndexComponent, data: { depth: 14 },
    children: [
      { path: '', component: BackProductsComponent, data: { depth: 15 } },
      { path: 'produits', component: BackProductsComponent, data: { depth: 16 } },
      { path: 'categories', component: BackCategoriesComponent, data: { depth: 17 } },
      { path: 'evenements', component: BackEventsComponent, data: { depth: 18 } },
      { path: 'news', component: BackNewsComponent, data: { depth: 19 } },
      { path: 'dossiers', component: BackDossiersComponent, data: { depth: 20 } },
      { path: 'videos', component: BackVideosComponent, data: { depth: 21 } }
    ],
    canActivate: [AuthGuard]
  },
  { path: 'catalogues/:q?', component: SearchComponent, data: { depth: 15 } },
  {
    path: '', component: IndexComponent, data: { depth: 1 },
    children: [
      { path: '', component: HomeComponent, data: { depth: 2 } },
      { path: 'produits', component: CategoryComponent, data: { depth: 3 } },
      { path: 'qui-sommes-nous', component: AboutComponent, data: { depth: 4 } },
      { path: 'events/:slug', component: EventComponent, data: { depth: 5 } },
      { path: 'documents/catalogue', component: CatalogComponent, data: { depth: 6 } },
      { path: 'documents/notices', component: QrcodesmartComponent, data: { depth: 7 } },
      { path: 'documents/:slug', component: DocumentComponent, data: { depth: 7 } },
      { path: 'gammes', component: CategoriesComponent, data: { depth: 8 } },
      { path: 'product/:slug', component: ProductComponent, data: { depth: 9 } },
      { path: 'series/:gamme/:categorie/:serie', component: CategoryComponent, data: { depth: 10 } },
      { path: 'categories/:gamme/:categorie', component: CategoryComponent, data: { depth: 11 } },
      { path: 'gammes/:gamme', component: CategoryComponent, data: { depth: 12 } },
      { path: 'products/search/:q', component: SearchComponent, data: { depth: 13 } },
      { path: 'contact', component: ContactComponent, data: { depth: 14 } },
      { path: 'login', component: LoginComponent, data: { depth: 15 } },
      { path: 'mentions-legales', component: MentionsComponent, data: { depth: 16 } },
      { path: 'videos', component: VideosComponent, data: { depth: 17 } },
      { path: 'videos/:video', component: VideoComponent, data: { depth: 18 } },
      { path: 'qrcode-smart', component: QrcodesmartComponent, data: { depth: 19 } },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
