import { FileUploader } from 'ng2-file-upload';
import {CategoryService} from './../../../services/category.service';
import {Component, Inject, OnInit} from '@angular/core';
import {DialogConfirmComponent} from '../../includes/dialog-confirm/dialog-confirm.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-back-categories',
  templateUrl: './back-categories.component.html',
  styleUrls: ['./back-categories.component.scss']
})
export class BackCategoriesComponent implements OnInit {
  public title = 'liste des catégories';
  filter = '';
  page = 1;
  limit = 5;
  model = {
    title: '', slug: '', active: false, categories: [], image: ""
  };
  public datatable: any = {
    headers: ['', 'Titre', 'Sous catégories'],
    items: [],
    paginate: {}
  };
  categories = [];

  constructor(
    private categoryService: CategoryService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar) {

  }

  addItem() {
    let item = {...this.model};
    this.dialog.open(CategoryAddComponent, {
      width: '70vw',
      data: {item, categories: this.categories}
    }).afterClosed().subscribe(result => {
      if (result) this.search()
    });
  }

  editItem(index) {
    let item = {... this.datatable.items[index]};
    this.dialog.open(CategoryEditComponent, {
      width: '70vw',
      data: {item, categories: this.categories}
    }).afterClosed().subscribe(result => {
      if (result) this.search();
    });
  }

  deleteItem(id) {
    this.dialog.open(DialogConfirmComponent, {
      data: {title: 'Supprimer', content: 'Êtes-vous sûr?'}
    })
      .afterClosed().subscribe(result => {
      if (result) {
        this.categoryService.delete(id).subscribe((response: any) => {
          this.search();
          if (response.status) {
            this.snackBar.open(response.message, '', {
              duration: 3000,
              panelClass: 'msg-success'
            });
            return;
          }
          this.snackBar.open(response.message, '', {
            duration: 3000,
            panelClass: 'msg-error'
          });
        })
      }
    });
  }

  search() {
    this.categoryService.allByFilter(this.filter, this.page, this.limit).subscribe(response => {
      this.datatable.items = response['data'];
      let {lastPage, page, perPage, total}: any = response;
      this.datatable.paginate = {total, lastPage, perPage}
    });
  }

  changeFilter(val) {
    this.filter = val;
    this.search();
  }

  changePage({pageIndex = 0, pageSize = 5}) {
    this.page = pageIndex + 1;
    this.limit = pageSize;
    this.search();
  }

  ngOnInit() {
    this.categoryService.allByFilter(this.filter, this.page, this.limit).subscribe((response: any) => {
      const {lastPage, page, perPage, total, data}: any = response;
      this.datatable.items = data;
      this.datatable.paginate = {total, lastPage, perPage};
    });
    this.categoryService.all().subscribe(categories => this.categories = categories);
  }
}


import {FileSelectDirective} from 'ng2-file-upload/ng2-file-upload';
import {FormControl} from "@angular/forms";
import {EventService} from "../../../services/event.service";
import {EventAddComponent} from "../back-events/back-events.component";

/*const URL = 'http://localhost:3333/api/v1/admin/categories/';
const URL_ADMIN = 'http://localhost:3333/api/v1/admin/';*/

const URL = 'https://api.arlux-lighting.fr/api/v1/admin/categories/';
const URL_ADMIN = 'https://api.arlux-lighting.fr/api/v1/admin/';

@Component({
  templateUrl: './edit.component.html',
})
export class CategoryEditComponent {
  objectKeys = Object.keys;
  toppings = new FormControl(this.data.item.subcategories_ids);
  public uploader: FileUploader = new FileUploader({
    url: URL + this.data.item._id + '/edit-picture?token=' + localStorage.getItem('arlux_access_token'),
    itemAlias: 'photo'
  });

  ngOnInit() {
    if (this.data.item.subcategories) {
      this.data.item.subcategories.forEach(cat => {
        this.data.item.subcategories_ids.push(cat._id)
      });
    }
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = JSON.parse(response);
      const {url, message} = response;
      this.data.item.image = url;
      this.snackBar.open(message, '', {
        duration: 3000,
        panelClass: 'msg-success'
      });
    };
  }

  editItem() {
    this.categoryService.update(this.data.item._id, this.data.item).subscribe(({status, message}: any) => {
      if (status == false) {
        this.snackBar.open(message[0].message, '', {
          duration: 3000,
          panelClass: 'msg-error'
        });
      } else {
        this.snackBar.open(message, '', {
          duration: 3000,
          panelClass: 'msg-success'
        });
        this.dialogRef.close(true);
      }
    });
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public snackBar: MatSnackBar,
              private dialogRef: MatDialogRef<CategoryEditComponent>,
              private categoryService: CategoryService) {
  }
}


@Component({
  templateUrl: './add.component.html',
})
export class CategoryAddComponent {
  objectKeys = Object.keys;
  toppings = new FormControl(this.data.item.subcategories_ids);
  public uploader: FileUploader = new FileUploader({
    url: URL_ADMIN + 'add-picture/categories?token=' + localStorage.getItem('arlux_access_token'),
    itemAlias: 'photo'
  });

  ngOnInit() {
    if (this.data.item.subcategories) {
      this.data.item.subcategories.forEach(cat => {
        this.data.item.subcategories_ids.push(cat._id)
      });
    }
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = JSON.parse(response);
      const {url, message} = response;
      this.data.item.image = url;
      this.snackBar.open(message, '', {
        duration: 3000,
        panelClass: 'msg-success'
      });
    };
  }

  addItem() {
    this.categoryService
      .store(this.data.item)
      .subscribe(({status, message}: any) => {
        if (status == false) {
          this.snackBar.open(message[0].message, '', {
            duration: 3000,
            panelClass: 'msg-error'
          });
        } else {
          this.snackBar.open(message, '', {
            duration: 3000,
            panelClass: 'msg-success'
          });
          this.dialogRef.close(true);
        }
      });
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public snackBar: MatSnackBar,
              private dialogRef: MatDialogRef<CategoryAddComponent>,
              private categoryService: CategoryService) {
  }
}
