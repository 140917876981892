import {AfterViewInit, Component, ElementRef, Input, ViewChild} from "@angular/core";

declare var jQuery: any;

@Component({
  selector: 'galleriesbox',
  template: `
    <div #gallery class="gallery">
      <ng-content></ng-content>
    </div>
  `,
  styles: [`
    .sl-prev, .sl-next, .sl-close {
      color: #fff !important;
    }
  `]
})

export class GalleriesBoxComponent implements AfterViewInit {
  @ViewChild('gallery') gallery: ElementRef;

  @Input() img = '';

  ngAfterViewInit() {
    jQuery(this.gallery.nativeElement).find('a').simpleLightbox();
  }
}
