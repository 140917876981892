import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  AfterViewInit
} from '@angular/core';

@Component({
  selector: 'arlux-datatable',
  templateUrl: './arlux-datatable.component.html',
  styleUrls: ['./arlux-datatable.component.scss']
})
export class ArluxDatatableComponent implements OnInit {

  @Input() headers: any = [];
  @Input() paginate: any;


  @Output() edit = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() changePage = new EventEmitter();

  editItem(index) {
    this.edit.emit(index);
  }

  deleteItem(index) {
    this.delete.emit(index);
  }

  changePageIndex(page) {
    this.changePage.emit(page);
  }

  constructor() {
  }

  ngOnInit(): void {
  }

}

