import { Component, OnChanges, OnInit } from "@angular/core";

import { ActivatedRoute } from "@angular/router";
import { ProductService } from "../../../services/product.service";
import { CategoryService } from "../../../services/category.service";
import {
  getColorByCategory,
  getInfosCategoryByTitle,
  getTreeCategories,
} from "../../../Helpers";
import { HttpClient } from "@angular/common/http";
declare var jQuery;
function imgError(image) {
  // Get image row
  var imageRow = image.parentNode.parentNode;
  // Get image label row
  var imageLabel = imageRow.nextElementSibling;
  // Get table element of that image row and remove imageLabel
  imageRow.parentNode.removeChild(imageLabel);
  // Get table element of that image row and remove img element
  imageRow.parentNode.removeChild(imageRow);
}
@Component({
  selector: "app-product",
  templateUrl: "./product.component.html",
  styleUrls: ["./product.component.scss"],
})
export class ProductComponent implements OnInit, OnChanges {
  gamme = null;
  category = null;
  serie = null;

  gammeCategories = [];

  parent = null;
  categoryInfos = { image: "", subtitle: "" };
  subcategories = [];
  title = "";
  descIsActive = true;
  techIsActive = true;
  logiIsActive = true;
  public product: any;
  public FT_filename: any;
  public Notice_filename: any;
  public descriptions: any = {};
  public specialities: any = {};
  public fieldsProduct: any = {};
  private sub: any;
  objectKeys = Object.keys;
  unities = {
    "puissance max": "(W)",
    lumens: "(Lm)",
    dimension: "(Cm)",
    "poids net": "(Kg)",
    "poids brut": "(Kg)",
  };

  constructor(
    private http: HttpClient,
    private categoryService: CategoryService,
    private productService: ProductService,
    private route: ActivatedRoute
  ) {}

  getBackgroundColorCategory(title) {
    return { "background-color": getColorByCategory(title) };
  }

  getLinks(slug) {
    let gamme = null;
    let category = null;
    let serie = null;
    this.categoryService.one(slug).subscribe((cat) => {
      if (cat.parent) {
        this.categoryService.one(cat.parent.slug).subscribe((cat1) => {
          if (cat1.parent) {
            serie = cat;
            category = cat1;
            gamme = cat1.parent;
          } else {
            category = cat;
            gamme = cat1;
          }
        });
      }
    });

    return { gamme, category, serie };
  }
  capitalizeString(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  capitalizeWords(str) {
    return str
      .split(" ")
      .map((word) => this.capitalizeString(word))
      .join(" ");
  }
  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      let slug = params["slug"];
      this.productService.one(slug).subscribe((product: any) => {
        let _descriptions = {};
        let _specialities = {};
        Object.keys(product.description).map((key) => {
          let value = product.description[key];
          if (value && value.trim() != "") {
            _descriptions[key] = value;
          }
        });

        Object.keys(product.specification).map((key) => {
          let value = product.specification[key];
          if (value && value.trim() != "") {
            _specialities[key] = value;
          }
        });

        this.fieldsProduct = Object.assign(_descriptions, _specialities);

        this.descriptions = _descriptions;
        this.specialities = _specialities;

        this.product = product;
        document.title = this.product.name + " - ARLUX-LIGHTING";
        let gamme = null;
        let category = null;
        let serie = null;
        this.categoryService.one(product.category.slug).subscribe((cat) => {
          if (cat.parent) {
            this.categoryService.one(cat.parent.slug).subscribe((cat1) => {
              if (cat1.parent) {
                serie = cat;
                category = cat1;
                gamme = cat1.parent;
              } else {
                category = cat;
                gamme = cat1;
              }

              this.gamme = gamme;
              this.category = category;
              this.serie = serie;
              let ean13 = this.product.ean13;
              let gammeTitle = gamme.title.toUpperCase();
              let serieTitle = serie.title.toUpperCase();
              // let categoryTitle = this.capitalizeWords(category.title)
              let categoryTitle = category.title;
              this.http
                .get(
                  "https://api.pim-arlux.fr/api/get_ft_notice_urls/" +
                    this.product.id
                )
                .subscribe(({ft, notice}: any) => {
                  this.FT_filename = ft;
                  this.Notice_filename = notice;
                });
              this.gammeCategories = getTreeCategories(gamme.slug);

              this.categoryService.one(gamme.slug).subscribe((_gamme) => {
                let title = _gamme.title;
                this.gammeCategories.forEach(async (cat, index) => {
                  let category = await this.categoryService
                    .one(cat.slug)
                    .toPromise();
                  if (this.gammeCategories[index].series.length == 0) {
                    this.gammeCategories[index].series = category.subcategories;
                  }
                });

                this.title = title;
                this.categoryInfos = getInfosCategoryByTitle(title);
              });
              });

          }
        });

        jQuery("#vue-360").append(`<iframe
                style="
                  top: 0;
                  left: 0;
                  bottom: 0;
                  right: 0;
                  max-width: 600px;
                  max-height: 800px;
                  background: #fff;
                "
                width="100%"
                height="100%"
                id="frame"
                frameBorder="0"
                src="https://360.arlux-lighting.fr/360_${product.ean13}"
                onerror="alert('Failed')"
              >not exist</iframe>`);
        jQuery("#vue-360-popup").html(`<div id="close-popup-360" style="
              position: absolute;
              left: 67.8%;
              top: 0;
              color: #333;
              padding: 6px 10px 2px 10px;
              background: #ddd;
              "><i class="material-icons" style="font-size: 40px;">close</i></div><iframe
                style="
                  top: 0;
                  left: 0;
                  bottom: 0;
                  right: 0;
                  max-width: 600px;
                  max-height: 800px;
                  background: #fff;
                "
                width="100%"
                height="100%"
                background="#fff"
                frameBorder="0"
                src="https://360.arlux-lighting.fr/360_${product.ean13}"
                onerror="alert('Failed')"
              ></iframe>`);

        jQuery(".fullscreen-360").on("click", function () {
          jQuery("#vue-360-popup").css("display", "block");
          jQuery("#vue-360-popup-over").css("display", "block");
        });
        jQuery("#vue-360-popup, #close-popup-360").on("click", function () {
          jQuery("#vue-360-popup").css("display", "none");
          jQuery("#vue-360-popup-over").css("display", "none");
        });

        jQuery("#frame").load(function () {
          setTimeout(function () {
            console.log(jQuery("#frame").contents().find("p.p404").length);
            jQuery("#vue-360").remove();
          }, 2000);
        });

        /*this.categoryService.one(product.category.slug).subscribe(category => {
                    this.category = category;
                    this.subcategories = category.subcategories;
                    let title = this.category.title;
                    if (category.parent) {
                        this.parent = category.parent;
                        title = this.parent.title;
                        this.categoryService.one(this.parent.slug).subscribe(parent => {
                            this.subcategories = parent.subcategories;
                        });
                    } else {
                        if (category.subcategories.length > 0) {
                            this.categoryService.one(category.subcategories[0].slug).subscribe(cat => {
                                this.category = cat;
                            });
                        }
                    }
                    this.title = title;
                    this.categoryInfos = getInfosCategoryByTitle(title);
                });*/
      });
    });

    jQuery("html, body").animate({ scrollTop: 0 }, 500);
  }

  isEmpty(text) {
    if (text) {
      return text.trim() == "";
    }
    return true;
  }

  ngOnChanges() {
    console.log("change");
  }

  getInfoByKey(field) {
    let value = this.fieldsProduct[field];
    if (value && value.trim() == "") {
      value = "";
      field = "";
    }
    if (value) {
      value = "" + value.trim();
      if (this.unities[field]) {
        value += " " + this.unities[field];
      }
    } else {
      value = "";
    }
    if (field === "dimension") {
      field += " produit";
    }

    return { field, value };
  }
}
