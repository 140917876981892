import {Component, OnInit} from '@angular/core';
import {NewsService} from '../../../services/news.service';
import {CategoryService} from '../../../services/category.service';
import {getColorByCategory, getInfosCategoryByTitle, getTreeCategories} from '../../../Helpers';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    news: any = [];
    currentNews: any = {title: '', description: ''};

    listGammes = [];
    products = [];

    currentIndex = 0;
    speed = 5000;
    infinite = true;
    direction = 'right';
    directionToggle = true;
    autoplay = true;
    categories = [
        {url: 'assets/images/home_slider/deco-xs.jpg', title: 'DECO'},
        {url: 'assets/images/home_slider/archi-xs.jpg', title: 'ARCHI'},
        {url: 'assets/images/home_slider/solar3-xs.jpg', title: 'SOLAR'},
        {url: 'assets/images/home_slider/aqua-xs.jpg', title: 'AQUA'},
        {url: 'assets/images/home_slider/brico3-xs.png', title: 'BRICO'},
        {url: 'assets/images/home_slider/garden-xs.jpg', title: 'GARDEN'},
        {url: 'assets/images/home_slider/indus-xs.jpg', title: 'INDUS'},
        {url: 'assets/images/home_slider/tech-xs.jpg', title: 'TECH'},
        {url: 'assets/images/home_slider/x-pro-xs.png', title: 'X-PRO'},
    ];

    avatars = this.categories.map((cat, i) => {
        const num = i;
        // const num = Math.floor(Math.random() * 1000);
        // if (i == 0) {
        return cat;
        // }
        /*return {
            url: `https://picsum.photos/1200/400/?${num}`,
            title: `${num}`
        };*/
    });


    constructor(private newsService: NewsService, private categoryService: CategoryService) {
    }

    currentSlider = 0;

    ngOnInit() {
        this.newsService.allNews().subscribe(response => {
            this.news = response;
            this.currentNews = {title: this.news[0].title, description: this.news[0].description};
            if (this.news.length > 1) {
                this.sliderNews();
            }
        });

        this.getGammesWithProducts();
    }

    getGammesWithProducts() {
        this.categoryService.allParents().subscribe(gammes => {
            this.listGammes = [];
            gammes.forEach(gamme => {
                const {title, slug, image} = gamme;
                const _gamme = {title, slug, image, products: []};
                getTreeCategories(gamme.slug).forEach(async (cat, index) => {
                    const cats = [];
                    const category = await this.categoryService.one(cat.slug).toPromise();

                    cats.push(category._id);
                    category.subcategories.forEach(serie => {
                        cats.push(serie._id);
                    });

                    this.categoryService.productsCategories(cats, 5).subscribe(products => {
                        products.forEach(p => {
                            if (_gamme.products.length < 5 ) {
                                _gamme.products.push(p);
                            }
                        });
                    });
                });
                this.listGammes.push(_gamme);
                console.log(this.listGammes);
            });
        });
    }

    getBackgroundColorCategoryProduct(title) {
        return {'background-color': getColorByCategory(title)};
    }

    sliderNews() {
        let index = 0;
        setInterval(() => {
            this.currentNews = {title: this.news[index].title, description: this.news[index].description};
            index++;
            if (this.news.length == index) {
                index = 0;
            }
        }, 3000);
    }

    getBackgroundColorCategory(title) {
        return {'borderColor': `${getColorByCategory(title)} transparent transparent transparent`};
    }

    getBorderColorCategory(title) {
        return {'border-color': `${getColorByCategory(title)}`};
    }
    getBorderColorCategorySlider(title) {
        return getColorByCategory(title);
    }
}
