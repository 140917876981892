import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  private prefix = 'news/';
  private prefixAdmin = 'admin/news/';

  constructor(private http: HttpClient) {
  }

  all(query, page, limit = 20) {
    return this.http.get(this.prefix + 'search/?q=' + query + '&page=' + page+ '&limit=' + limit)
  }

  search(query, page, limit = 20) {
    return this.http.get(this.prefix + 'search/?q=' + query + '&page=' + page+ '&limit=' + limit)
  }
  allNews() {
    return this.http.get(this.prefix + 'all-news')
  }

  one(slug) {
    return this.http.get(this.prefix + slug)
  }

  delete(id) {
    return this.http.delete(this.prefixAdmin + id)
  }
  update(id, item) {
    return this.http.put(this.prefixAdmin + id,item)
  }
  store(item) {
    return this.http.post(this.prefixAdmin,item)
  }

}
