export default {
  MENU: {
    HOME: "Accueil",
    ABOUT: "Qui sommes nous?",
    CATEGORIES: {
      CATEGORIES: "Catégories",
      GAMMES: "Gammes",
      Architectural_Lighting: "Éclairage architectural",
      Technique_Lighting: "Éclairage Technique",
      Construction_Projector: "Projecteur de chantier",
      Wall_Washer: "Projecteur mural",
      Exterior_Lighting: "Éclairage extérieur",
      Solar_Lighting: "Éclairage solaire",
      Tertiary_Lighting: "Éclairage tertiaire",
    },
    DOCUMENTS: {
      DOCUMENTS: "Documents",
      Catalogue: "Catalogue",
      Notices: "Notices",
      Products_Documentation: "Documentation des produits",
      Declarations_of_conformity: "Déclarations de conformité"
    },
    Events: {
      Events: "Événements",
      Trade_fair: "SALON"
    },
    CONTACT: "Contactez Nous",
    VIDEOS: "Vidéos"
  },
  NEWS: "NOUVELLES",
  Search: "Recherche",
  'Type to search a product': "Tapez pour rechercher un produit",

  Learn_more: "En savoir +",

  'Notre_gamme_destinee_aux_professionnels': "Notre gamme destinée aux professionnels",
  "Notre_gamme_design_pour_les_architectes": "Notre gamme design pour les architectes",
  "Notre_gamme_destinee_eclairage_des_jardins": "Notre gamme destinée à l'éclairage des jardins",
  "Notre_gamme_projecteur_chantier": "Notre gamme de projecteur de chantier",

  "Site_web": "Site Web",
  "Coordonnees": "Coordonnées",

  PAGE_ABOUT: {
    "qui_sommes_nous": "Qui sommes nous ?",
    "design_creatif": "DESIGN CRÉATIF & SOLUTION INTELLIGENTE POUR L'ÉCLAIRAGE",
    "about_description": "<b>ARLUX</b> est le prolongement d’une expérience de plusieurs décennies, dans l’éclairage domestique et professionnel. De la création de designs originaux; en passant par la production, et les contrôles de conformité et de performance, les équipes d’ARLUX en Europe, comme en Chine, s’efforcent à apporter les meilleures solutions d'éclairage à nos clients.",
    "usine": "USINE AVEC DES ÉQUIPEMENTS MODERNES ET PUISSANTS",
    "experience": "Une expérience qualifiée aux fils des décennies dans l'éclairage des entreprises pour les marchés privés et professionnels, en intérieure et extérieure.",
    "qualite": "Notre \"Contrôle Qualité / Audit\" au niveau de l'Europe et notre équipe qualifiée et spécialisée, sont des gages pour nos clients de notre engagement à leur fournir la meilleur solution d'éclairage au prix le plus abordable.",
    "savoir_faire": "SAVOIR FAIRE",
    "expertise": "Expertise depuis 1988",
    "flexibility": "Flexibilité et disponibilité",
    "reseau": "Un réseau de partenaire",
    "team": "Une équipe à votre service",
    "events": "évenements",
  },

  "Projecteur de Chantier": "Projecteur de Chantier",
  "Spécification": "Spécification",
  "Télécharger": "Télécharger",
  "Télécharger la notice en PDF": "Télécharger la notice en PDF",
  "Voir le catalogue optimisé pour l\'affichage web": "Voir le catalogue optimisé pour l\'affichage web",
  "Voir le catalogue": "Voir le catalogue",
  "Télécharger le catalogue en version haute définition": "Télécharger le catalogue en version haute définition",
  "Télécharger le catalogue HD": "Télécharger le catalogue HD",

  "Catalogue": "catalogue",
  "NOTICES PRODUITS": "NOTICES PRODUITS",
  "FICHES PRODUITS": "FICHES PRODUITS",
  "DÉCLARATIONS DE CONFORMITÉS": "DÉCLARATIONS DE CONFORMITÉS",

  "Aperçu": "Aperçu",
  "Tel": "Tél",
  "Télécharger les informations": "Télécharger les informations",
  "Nom": "Nom",
  "Envoyer": "Envoyer", "Sujet": "Sujet",
  "Message": "Message",
  "Le nom est requis": "Le nom est requis",
  "Email est requis": "Email est requis",
  "le formulaire de contact a bien été envoyé": "Le formulaire de contact a bien été envoyé",
  "Nom de série": "Nom de série",

  "RESULTS": "Environ {{value}} résultats trouvés",
  "LIST_VIDEOS": "LISTE VIDÉOS",
  "VIDEO": "VIDÉO",
  "show all": "Afficher Tout",
}
