import {Component, OnInit} from '@angular/core';
import {CategoryService} from "../../../services/category.service";
import { getInfosCategoryByTitle } from '../../../Helpers';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  categories = [];

  constructor(private categoryService: CategoryService) {
  }

  ngOnInit() {
    document.title = "GAMMES - ARLUX-LIGHTING";
    this.categoryService.allParents().subscribe(categories => this.categories = categories);
  }

  getInfosCategoryByTitle = title => getInfosCategoryByTitle(title)

}
