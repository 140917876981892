import { FileUploader } from 'ng2-file-upload';
import { Component, Inject, OnInit } from '@angular/core';
import { DialogConfirmComponent } from '../../includes/dialog-confirm/dialog-confirm.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-back-videos',
  templateUrl: './back-videos.component.html',
  styleUrls: ['./back-videos.component.scss']
})
export class BackVideosComponent implements OnInit {
  public title = 'liste des vidéos';
  hoverImg = -1;
  filter = '';
  page = 1;
  limit = 5;
  model = {
    title: '', slug: '', video: '', description: '', image: ''
  };
  public datatable: any = {
    headers: ['', 'Titre', 'Description'],
    items: [],
    paginate: {}
  };
  categories = [];

  constructor(
    private videoService: VideoService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar) {

  }

  addItem() {
    let item = { ...this.model };
    this.dialog.open(VideoAddComponent, {
      width: '70vw',
      data: { item }
    }).afterClosed().subscribe(result => {
      if (result) this.search()
    });
  }

  editItem(index) {
    let item = { ... this.datatable.items[index] };
    this.dialog.open(VideoEditComponent, {
      width: '70vw',
      data: { item, categories: this.categories }
    }).afterClosed().subscribe(result => {
      if (result) this.search()
    });
  }

  deleteItem(id) {
    this.dialog.open(DialogConfirmComponent, {
      data: { title: 'Supprimer', content: 'Êtes-vous sûr?' }
    })
      .afterClosed().subscribe(result => {
        if (result) {
          this.videoService.delete(id).subscribe((response: any) => {
            this.search();
            if (response.status) {
              this.snackBar.open(response.message, '', {
                duration: 3000,
                panelClass: 'msg-success'
              });
              return;
            }
            this.snackBar.open(response.message, '', {
              duration: 3000,
              panelClass: 'msg-error'
            });
          })
        }
      });
  }

  search() {
    this.videoService.search(this.filter, this.page, this.limit).subscribe(response => {
      this.datatable.items = response['data'];
      let { lastPage, page, perPage, total }: any = response;
      this.datatable.paginate = { total, lastPage, perPage }
    });
  }

  changeFilter(val) {
    this.filter = val;
    this.search();
  }

  changePage({ pageIndex = 0, pageSize = 5 }) {
    this.page = pageIndex + 1;
    this.limit = pageSize;
    this.search();
  }

  ngOnInit() {
    this.videoService.search(this.filter, this.page, this.limit).subscribe((response: any) => {
      const { lastPage, page, perPage, total, data }: any = response;
      this.datatable.items = data;
      this.datatable.paginate = { total, lastPage, perPage };
    });
  }
}


import { FileSelectDirective } from 'ng2-file-upload/ng2-file-upload';
import { VideoService } from "../../../services/video.service";
// import { VideoAddComponent } from "../back-videos/back-videos.component";
// import { VideoService } from "../../../services/video.service";

/*const URL = 'http://localhost:3333/api/v1/admin/videos/';
const URL_ADMIN = 'http://localhost:3333/api/v1/admin/';*/

const URL = 'https://api.arlux-lighting.fr/api/v1/admin/videos/';
const URL_ADMIN = 'https://api.arlux-lighting.fr/api/v1/admin/';

@Component({
  templateUrl: './edit.component.html',
  styles: [`
    .delete-img {
      position: absolute;
      top: 0;
      right: 0;
    }
  `]
})
export class VideoEditComponent {
  objectKeys = Object.keys;
  hoverImg = -1;
  public uploader: FileUploader = new FileUploader({
    url: URL + this.data.item._id + '/edit-picture?token=' + localStorage.getItem('arlux_access_token'),
    itemAlias: 'photo'
  });

  public uploaderVideo: FileUploader = new FileUploader({
    url: URL + this.data.item._id + '/edit-video?token=' + localStorage.getItem('arlux_access_token'),
    itemAlias: 'video'
  });

  removeImg(i) {
    this.videoService.removeImg(this.data.item._id, i).subscribe((res: any) => {
      if (res.success) {
        this.data.item.image = "";
        this.snackBar.open(res.message, '', {
          duration: 3000,
          panelClass: 'msg-success'
        });
      }
    })
  }

  removeVideo(i) {
    this.videoService.removeVideo(this.data.item._id, i).subscribe((res: any) => {
      if (res.success) {
        this.data.item.video = "";
        this.snackBar.open(res.message, '', {
          duration: 3000,
          panelClass: 'msg-success'
        });
      }
    })
  }

  editItem() {
    this.videoService.update(this.data.item._id, this.data.item).subscribe(({ status, message }: any) => {
      if (status == false) {
        this.snackBar.open(message[0].message, '', {
          duration: 3000,
          panelClass: 'msg-error'
        });
      } else {
        this.snackBar.open(message, '', {
          duration: 3000,
          panelClass: 'msg-success'
        });
        this.dialogRef.close(true);
      }

    });
  }

  ngOnInit() {
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = JSON.parse(response);
      const { url, message } = response;
      this.data.item.image = url;
      this.snackBar.open(message, '', {
        duration: 3000,
        panelClass: 'msg-success'
      });
    };

    this.uploaderVideo.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };
    this.uploaderVideo.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = JSON.parse(response);
      const { url, message } = response;
      this.data.item.video = url;
      this.snackBar.open(message, '', {
        duration: 3000,
        panelClass: 'msg-success'
      });
    };
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<VideoEditComponent>,
    private videoService: VideoService) {
  }
}

@Component({
  templateUrl: './add.component.html',
  styles: [`
    .delete-img {
      position: absolute;
      top: 0;
      right: 0;
    }
  `]
})
export class VideoAddComponent {
  objectKeys = Object.keys;
  hoverImg = -1;
  public uploader: FileUploader = new FileUploader({
    url: URL_ADMIN + 'add-picture/videos?token=' + localStorage.getItem('arlux_access_token'),
    itemAlias: 'photo'
  });

  public uploaderVideo: FileUploader = new FileUploader({
    url: URL_ADMIN + 'add-video/videos?token=' + localStorage.getItem('arlux_access_token'),
    itemAlias: 'video'
  });

  ngOnInit() {
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = JSON.parse(response);
      const { url, message } = response;
      this.data.item.image = url;
      this.snackBar.open(message, '', {
        duration: 3000,
        panelClass: 'msg-success'
      });
    };

    this.uploaderVideo.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };
    this.uploaderVideo.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = JSON.parse(response);
      const { url, message } = response;
      this.data.item.video = url;
      this.snackBar.open(message, '', {
        duration: 3000,
        panelClass: 'msg-success'
      });
    };
  }

  removeImg(i) {
    this.data.item.image = "";
    this.snackBar.open('l\'image a été supprimée', '', {
      duration: 3000,
      panelClass: 'msg-success'
    });
  }

  removeVideo(i) {
    this.data.item.video = "";
    this.snackBar.open('la vidéo a été supprimée', '', {
      duration: 3000,
      panelClass: 'msg-success'
    });
  }

  addItem() {
    this.videoService.store(this.data.item).subscribe(({ status, message }: any) => {
      if (status == false) {
        this.snackBar.open(message[0].message, '', {
          duration: 3000,
          panelClass: 'msg-error'
        });
      } else {
        this.snackBar.open(message, '', {
          duration: 3000,
          panelClass: 'msg-success'
        });
        this.dialogRef.close(true);
      }
    });
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, public snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<VideoAddComponent>,
    private videoService: VideoService) {
  }
}
