import {Injectable} from '@angular/core';
import {Event} from '../models/Event';
import {HttpClient} from '@angular/common/http';
import {Observable} from "rxjs/index";

@Injectable({
  providedIn: 'root'
})
export class EventService {
  private prefix = 'events/';
  private prefixAdmin = 'admin/events/';

  constructor(private http: HttpClient) {
  }

  all(): Observable<Event[]> {
    return this.http.get<Event[]>(this.prefix + 'all');
  }

  search(query, page, limit = 20) {
    return this.http.get(this.prefix + 'search/?q=' + query + '&page=' + page + '&limit=' + limit)
  }

  one(slug): Observable<Event> {
    return this.http.get<Event>(this.prefix + 'one/' + slug);
  }

  delete(id) {
    return this.http.delete(this.prefixAdmin + id)
  }

  update(id, item) {
    return this.http.put(this.prefixAdmin + id, item)
  }

  store(item) {
    return this.http.post(this.prefixAdmin, item)
  }

  removeImg(id,index) {
    return this.http.post(this.prefixAdmin + id +'/remove-picture/' + index, {})
  }

}
