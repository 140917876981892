import {Component, Inject, OnInit} from '@angular/core';
import {DialogConfirmComponent} from '../../includes/dialog-confirm/dialog-confirm.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {FileUploader, FileSelectDirective} from 'ng2-file-upload/ng2-file-upload';
import {NewsService} from "../../../services/news.service";

@Component({
  selector: 'app-back-news',
  templateUrl: './back-news.component.html',
  styleUrls: ['./back-news.component.scss']
})
export class BackNewsComponent implements OnInit {
  public title = 'Liste des nouvelles';
  filter = '';
  page = 1;
  limit = 5;
  model = {
    title: '', slug: '', description: ''
  };
  public datatable: any = {
    headers: ['', 'Titre', 'Description'],
    items: [],
    paginate: {}
  };

  constructor(
    private newsService: NewsService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar) {

  }

  addItem() {
    let item = {...this.model};
    this.dialog.open(NewsAddComponent, {
      width: '70vw',
      data: {item}
    }).afterClosed().subscribe(result => {
      if (result) this.search()
    });
  }

  editItem(index) {
    let item = {... this.datatable.items[index]};
    this.dialog.open(NewsEditComponent, {
      width: '70vw',
      data: {item}
    }).afterClosed().subscribe(result => {
      if (result) this.search();

    });
  }

  deleteItem(id) {
    this.dialog.open(DialogConfirmComponent, {
      data: {title: 'Supprimer', content: 'Êtes-vous sûr?'}
    })
      .afterClosed().subscribe(result => {
      if (result) {
        this.newsService.delete(id).subscribe((response: any) => {
          this.search();
          if (response.status) {
            this.snackBar.open(response.message, '', {
              duration: 3000,
              panelClass: 'msg-success'
            });
            return;
          }
          this.snackBar.open(response.message, '', {
            duration: 3000,
            panelClass: 'msg-error'
          });
        })
      }
    });
  }

  search() {
    this.newsService.search(this.filter, this.page, this.limit).subscribe(response => {
      this.datatable.items = response['data'];
      let {lastPage, page, perPage, total}: any = response;
      this.datatable.paginate = {total, lastPage, perPage}
    });
  }

  changeFilter(val) {
    this.filter = val;
    this.search();
  }

  changePage({pageIndex = 0, pageSize = 5}) {
    this.page = pageIndex + 1;
    this.limit = pageSize;
    this.search();
  }

  ngOnInit() {
    this.newsService.search(this.filter, this.page, this.limit).subscribe((response: any) => {
      const {lastPage, page, perPage, total, data}: any = response;
      this.datatable.items = data;
      this.datatable.paginate = {total, lastPage, perPage};
    });
  }
}

const URL = 'http://localhost:3333/api/v1/admin/news/';

@Component({
  templateUrl: './edit.component.html',
})
export class NewsEditComponent {
  objectKeys = Object.keys;

  editItem() {
    this.newsService.update(this.data.item._id, this.data.item).subscribe(({status, message}: any) => {
      if (status == false) {
        this.snackBar.open(message[0].message, '', {
          duration: 3000,
          panelClass: 'msg-error'
        });
      } else {
        this.snackBar.open(message, '', {
          duration: 3000,
          panelClass: 'msg-success'
        });
        this.dialogRef.close(true);
      }

    });
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public snackBar: MatSnackBar,
              private dialogRef: MatDialogRef<NewsEditComponent>,
              private newsService: NewsService) {
  }
}

@Component({
  templateUrl: './add.component.html',
})
export class NewsAddComponent {
  objectKeys = Object.keys;

  addItem() {
    this.newsService.store(this.data.item).subscribe(({status, message}: any) => {
      if (status == false) {
        this.snackBar.open(message[0].message, '', {
          duration: 3000,
          panelClass: 'msg-error'
        });
      } else {
        this.snackBar.open(message, '', {
          duration: 3000,
          panelClass: 'msg-success'
        });
        this.dialogRef.close(true);
      }
    });
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, public snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<NewsAddComponent>,
    private newsService: NewsService) {
  }
}
