import {AfterViewInit, Component, ElementRef, Input, ViewChild} from "@angular/core";

declare var jQuery: any;

@Component({
  selector: 'gallerybox',
  template: `
    <div class="gallery">
      <a #gallery [href]="img">
        <img [src]="img">
      </a>
    </div>
  `
})

export class GalleryBoxComponent implements AfterViewInit {
  @ViewChild('gallery') gallery: ElementRef;

  @Input() img = '';

  ngAfterViewInit() {
    jQuery(this.gallery.nativeElement).simpleLightbox();
  }
}
