import {Component, OnInit} from '@angular/core';
import {EventService} from '../../../services/event.service';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
    events: any = [];

    constructor(private eventService: EventService) {
    }

    getEvents() {
        this.eventService.all().subscribe(events => this.events = events);
    }

    ngOnInit() {
        this.getEvents();
    }

}
