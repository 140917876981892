export default {
  MENU: {
    HOME: "Home",
    ABOUT: "Who are we ?",
    CATEGORIES: {
      CATEGORIES: "Categories",
      GAMMES: "Ranges",
      Architectural_Lighting: "Architectural Lighting",
      Technique_Lighting: "Technique Lighting",
      Construction_Projector: "Construction Projector",
      Wall_Washer: "Wall Washer",
      Exterior_Lighting: "Exterior Lighting",
      Solar_Lighting: "Solar Lighting",
      Tertiary_Lighting: "Tertiary Lighting",
    },
    DOCUMENTS: {
      DOCUMENTS: "Documents",
      Catalogue: "Catalogue",
      Notices: " Notices",
      Products_Documentation: "Products Documentation",
      Declarations_of_conformity: " Declarations of conformity",
    },
    Events: {
      Events: "Events",
      Trade_fair: "Trade fair",
    },
    VIDEOS: "Videos",
    CONTACT: "Contact us",
  },
  NEWS: "NEWS",
  Search: "Search",
  "Tapez pour rechercher un produit": "Type to search a product",
  "S'identifier": "Login",
  Learn_more: "Learn more",

  Tertiaire: "TERTIARY",
  Notre_gamme_destinee_aux_professionnels: "Our range for professionals",
  Professionnel: "PROFESSIONAL",

  Architectural: "EXTERIOR",
  Design: "ARCHITECTURAL",
  Notre_gamme_design_pour_les_architectes: "Our design range for architects",

  Extérieur: "EXTERIOR",
  Domestique: "DOMESTIC",
  Notre_gamme_destinee_eclairage_des_jardins: "Our range for lighting gardens",

  Projecteur: "PROJECTOR",
  Notre_gamme_projecteur_chantier: "Our range of construction projector",

  Eclairage: "Lighting",
  Solaire: "Solar",
  Site_web: "WebSite",
  Coordonnees: "Contact information",

  PAGE_ABOUT: {
    qui_sommes_nous: "ABOUT US",
    design_creatif: "CREATIVE DESIGN & SMART SOLUTION FOR LIGHTING",
    about_description:
      "<b>ARLUX</b> is a continuation of a decades of experience in the domestic and professional lighting. Creating originaux designs through production, and compliance controls and performance, the ARLUX teams in Europe, as in China, strive to provide the best lighting solutions for our customers.",
    usine: "FACTORY WITH MODERN AND POWERFUL FACILITIES",
    experience:
      "Company is the extension of a profession and skilled experiences over decades in lighting business for private and professional, indoor and outdoor markets.",
    qualite:
      "Our integrated “Conformity Audit / Quality Control” unit , European protected designs and our skilled and specialized team ; are cautions given to our customers for a strong commitment to provide them the best and affordable solution of lighting.",
    savoir_faire: "KNOW HOW",
    expertise: "Expertise since 1988",
    flexibility: "Flexibility and availability",
    reseau: "A partner network",
    team: "A team at your service",
    events: "events",
  },

  "Projecteur de Chantier": "Construction Spotlight",
  Spécification: "Specification",
  Télécharger: "Download",
  "Télécharger la notice en PDF": "Download the notice in PDF",
  "Voir le catalogue optimisé pour l'affichage web":
    "See the optimized catalog for web display",
  "Voir le catalogue": "See the catalog",
  "Télécharger le catalogue en version haute définition":
    "Download the catalog in high definition version",
  "Télécharger le catalogue HD": "Download HD catalog",

  Catalogue: "catalog",
  "NOTICES PRODUITS": "PRODUCT NOTICES",
  "FICHES PRODUITS": "PRODUCTS DOCUMENTATION",
  "DÉCLARATIONS DE CONFORMITÉS": "DECLARATIONS OF CONFORMITY",
  Aperçu: "Preview",
  Tel: "Phone",
  "Télécharger les informations": "Download informations",
  Nom: "Name",
  Envoyer: "Send",
  Sujet: "Subject",
  Message: "Message",
  "Le nom est requis": "The name is required",
  "Email est requis": "Email is required",
  "le formulaire de contact a bien été envoyé":
    "The contact form has been sent",

  gamme: "Range",
  "éclairage technique": "Technical lighting",
  "éclairage de puissance": "power lighting",
  "éclairage chantier": "site lighting",
  "éclairage professionnel": "professional lighting",
  "éclairage extérieur": "exterior lighting",
  "éclairage autonome": "autonomous lighting",
  "éclairage architectural": "architectural lighting",
  "éclairage étanche": "waterproof lighting",
  "éclairage d'ambiance": "mood lighting",
  "éclairage connecté": "connected lighting",
  "éclairage industriel": "industrial lighting",
  "Voir le site officiel": "See the official website",
  "Nom de série": "Serial name",
  coloris: "Color",
  "puissance max": "max power",
  matiere: "material",
  famille: "family",
  "duree vie": "life time",
  "type d'alimentation": "type of power",
  classe: "class",
  "type detct": "type detct",
  "temp kelvin": "temperature kelvin",

  "poids net": "net weight",
  "poids brut": "gross weight",
  "dimension produit": "product dimension",

  Description: "Description",
  "Spécifications Techniques": "TECHNICAL SPECIFICATIONS",
  "Données Logistique": "LOGISTIC DATA",
  "type batterie": "battery type",
  "capacite bat mah": "battery capacity mah",
  "capacite bat cycle": "battery capacity cycle",
  "capacite bat h": "battery capacity h",

  "Aucun résultats trouvés": "No results found",
  RESULTS: "About {{value}} results found",
  LIST_VIDEOS: "LIST VIDEOS",
  VIDEO: "VIDEO",
  "show all": "Show all",
  "Rechercher un article": "Search an article",
};
