const setCookie = (cname, cvalue, exdays = 356) => {
    let d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

const getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};
const colors = {
    aqua: "#00a8a9",
    archi: "#263475",
    worklight: "#ed6c22",
    decotech: "#1c232b",
    garden: "#94c121",
    indus: "#888b8c",
    smart: "#1c232b",
    solar: "#ffe507",
    tech: "#ce132d",
    "x-pro": "#ed6c22",
    prime: "#C3C5C4",
};
const getColorByCategory = (title) => {
    return colors[title.toLowerCase()];
};

const categories = {
    archi: {
        subtitle: "éclairage intérieur",
        image: "assets/images/categories_home/v2/ARCHI.png",
    },
    decotech: {
        subtitle: "éclairage d'ambiance",
        image: "assets/images/categories_home/v2/DECO.png",
    },
    solar: {
        subtitle: "éclairage solaire",
        image: "assets/images/categories_home/v2/SOLAR.png",
    },
    garden: {
        subtitle: "éclairage extérieur",
        image: "assets/images/categories_home/v2/GARDEN.png",
    },
    tech: {
        subtitle: "éclairage technique",
        image: "assets/images/categories_home/v2/TECH.png",
    },
    worklight: {
        subtitle: "éclairage chantier",
        image: "assets/images/categories_home/v2/BRICO.png",
    },
    "x-pro": {
        subtitle: "éclairage chantier pro",
        image: "assets/images/categories_home/v2/XPRO.png",
    },
    indus: {
        subtitle: "éclairage industriel",
        image: "assets/images/categories_home/v2/INDUS.png",
    },
    aqua: {
        subtitle: "éclairage pièce humide",
        image: "assets/images/categories_home/v2/AQUA.png",
    },
    smart: {
        subtitle: "éclairage connecté",
        image: "assets/images/categories_home/v2/SMART.png",
    },
    prime: {
        subtitle: "éclairage prime",
        // subtitle: " ",
        image: "assets/images/categories_home/v2/PRIME.png",
    },
};
const getInfosCategoryByTitle = (title) => {
    return categories[title];
};

const getTreeCategories = (gamme) => {
    let categoriesGammes = {
        archi: [
            'Accessoires',
            'Accessoires Archi',
            'Dalles',
            'Détecteurs',
            'Drivers',
            'Spots Interieur',
            'Spots sur Rail'
        ],
        solar: [
            'Appliques Solaires',
            'Bornes Solaires',
            'Chargeurs',
            'Piquets Solaires',
            'Potelets Solaires',
            'Projecteurs Solaires',
            'Spots Solaires',
            'Streetlights Solaires'
        ],
        garden: [
            'Appliques Exterieur',
            'Bornes Exterieur',
            'Connecteurs IP',
            'Lampadaires Exterieur',
            'Potelets Exterieur',
            'Projecteurs a Poser',
            'Projecteurs Muraux',
            'Projecteurs Trepied',
            'Spots au Sol',
            'Spots sur Piquets',
            'Strips Leds',
            'Suspensions Exterieur'
        ],
        tech: [
            'Accessoires',
            'Appliques Exterieur',
            'Blocs Prises',
            'Connecteurs IP',
            'Projecteurs Muraux',
            'Reglettes',
            'Spots Meubles'
        ],
        worklight: [
            'Batteries',
            'Chargeurs',
            'Lampe torche',
            'Lampes Rasantes',
            'Projecteurs a Poser',
            'Projecteurs Batteries',
            'Projecteurs Trepied',
            'Trepieds'
        ],
        aqua: ['Appliques SDB', 'Reglettes', 'Spots SDB'],
        decotech: [
            'Accessoires',
            'Accessoires Decotech',
            'Appliques CHR',
            'Drivers',
            'Profils Aluminiums',
            'Sources',
            'Strips Leds'
        ],
        indus: [
            'Accessoires Indus',
            'Candelabres',
            'Detecteurs',
            'Highbay',
            'Projecteurs',
            'Projecteurs Muraux',
            'Reglettes'
        ],
        smart: ['Dalles', 'Sources', 'Spots Interieur'],
        "x-pro": ["projecteurs", "trepieds"],
        prime: ['Collerettes', 'Projecteurs Muraux', 'Sources', 'Spots Interieur', 'Spots SDB', 'Suspensions Interieur'],
    };
    let gammes = {};
    Object.keys(categoriesGammes).map((_gamme) => {
        gammes[_gamme] = getDataGamme(_gamme, categoriesGammes[_gamme]);
    });
    return gammes[gamme];
};

function getDataGamme(_gamme, _categories) {
    let categories = [];
    _categories.forEach((category) => {
        categories.push(getObjCategory(_gamme, category));
    });
    return categories;
}

function getObjCategory(gamme, category) {
    return {
        title: category,
        slug: gamme + "-" + stringToSlug(category),
        series: [],
    };
}

const stringToSlug = (str) => {
    str = str.replace(/^\s+|\s+$/g, "");
    str = str.toLowerCase();

    let from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
    let to = "aaaaaaeeeeiiiioooouuuunc------";

    for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
        .replace(/[^a-z0-9 -]/g, "")
        .replace(/\s+/g, "-")
        .replace(/-+/g, "-");

    return str;
};

export {
    setCookie,
    getCookie,
    getColorByCategory,
    getInfosCategoryByTitle,
    getTreeCategories,
};