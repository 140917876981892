import {Component} from '@angular/core';
import {trigger, transition, group, query, style, animate, animateChild, state} from '@angular/animations';
import {Router} from '@angular/router';
import {EventService} from '../../../services/event.service';
import {TranslateService} from '@ngx-translate/core';
import fr from '../../../lang/fr';
import en from '../../../lang/en';
import {AuthService} from '../../../services/auth.service';
import {getCookie, setCookie, getColorByCategory} from '../../../Helpers';
import {CategoryService} from '../../../services/category.service';
declare var jQuery;
@Component({
    selector: 'app-root',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.scss'],

    animations: [
        trigger('animRoutes', [

            transition('* => *', [

                query(':enter',
                    [
                        style({opacity: 0})
                    ],
                    {optional: true}
                ),

                query(':leave',
                    [
                        style({opacity: 1}),
                        animate('0.01s', style({opacity: 0}))
                    ],
                    {optional: true}
                ),

                query(':enter',
                    [
                        style({opacity: 0}),
                        animate('0.1s', style({opacity: 1}))
                    ],
                    {optional: true}
                )

            ])

        ])
    ]
})
export class IndexComponent {
    title: string = 'app';
    currentMenu: string = '';
    events: any = [];
    categories: any = [];
    search = '';
    ukActive = false;

    getStyleColorCategories(title) {
        return {
            width: '11px',
            height: '11px',
            background: getColorByCategory(title),
            display: 'inline-block',
            'margin-right': '9px',
            'border-radius': '50%'
        };
    }

    getDepth(outlet) {
        return outlet.activatedRouteData['depth'];
    }

    getEvents() {
        this.eventService.all().subscribe(events => this.events = events);
    }

    gotToTop() {
        jQuery('html, body').animate({scrollTop: 0}, 500);
    }

    getCategories() {
        this.categoryService.allParents().subscribe(categories => this.categories = categories);
    }

    ngOnInit() {
        this.getEvents();
        this.getCategories();
    }

    handleChangeText(e) {
        let code = e.keyCode ? e.keyCode : e.charCode;
        this.search = e.target.value;
        if (code == 13) {
          this.goSearch();
        }
      }
    goSearch() {
        this.router.navigate(['/products/search', this.search]);
    }

    handleChangeLang(lang) {
        this.translate.use(lang);
        setCookie('arlux-lang', lang);
    }

    constructor(public translate: TranslateService, public auth: AuthService,
                private eventService: EventService, private categoryService: CategoryService, private router: Router) {
        translate.addLangs(['en', 'fr']);
        translate.setDefaultLang('fr');

        let browserLang = translate.getBrowserLang();
        if (getCookie('arlux-lang')) {
            browserLang = getCookie('arlux-lang');
        }

        translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');

        translate.setTranslation('en', en);
        translate.setTranslation('fr', fr);
    }
}
