import { Component, OnInit } from '@angular/core';
import {CatalogService} from "../../../services/catalog.service";

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss']
})
export class CatalogComponent implements OnInit {

  catalog: any;

  constructor(private catalogService: CatalogService) {
  }

  ngOnInit() {
      this.catalogService.one().subscribe(catalog => this.catalog = catalog);
  }

}
