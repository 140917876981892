import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs/index";

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  private prefixUrl = 'documents/';

  constructor(private http: HttpClient) {
  }

  all(slug): Observable<any> {
    return this.http.get(this.prefixUrl + slug);
  }

}
