import {Component, Input} from '@angular/core';

@Component({
    selector: '[infos]',
    template: `
        <div class="field">{{info.field | translate}}</div>
        <div class="value" *ngIf="info.value  != ''">{{info.value}}</div>
        <div class="value" *ngIf="info.value == ''">_</div>
    `,
    styles: [`
        .field {
            color: #b1acac;
            margin-bottom: 5px;
            font-weight: 100;
            font-size: 16px;
        }

        .value {
            font-weight: bold;
            font-size: 15px;
        }
    `]
})
export class InfoComponent {
    @Input() info: any = {field: '', value: ''};
}
