import { Component, OnInit } from '@angular/core';
import { Video } from '../../../models/Video';
import { ActivatedRoute } from '@angular/router';
import { VideoService } from "../../../services/video.service";

declare var jQuery: any;

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements OnInit {
  videos: Video[];
  sub: any;

  constructor(private videoService: VideoService, private route: ActivatedRoute, ) {
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.videoService.all().subscribe(videos => {
        this.videos = videos;
      });

    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
